.slick-prev, .slick-next {
  width: 40px; /* Ensure the clickable area is appropriate */
  height: 40px;
  z-index: 10;
}

.slick-prev::before, .slick-next::before {
  font-size: 40px; /* Increase the size of the arrow icons */
  color: white; /* Set the color of the arrows */
}

.slick-prev {
  left: 10px; /* Position the left arrow */
}

.slick-next {
  right: 10px; /* Position the right arrow */
}

