/* pagination.css */
/* .pagination{
    display: flex;
    gap: 0.5rem;
    border-bottom: 0;
}
.page-item{
    border: 1px solid black;
    font-size: large;
    padding: 3px 10px;
    border-radius: 0;
    
}
.page-item:hover{
    background: black ;
    color: white
} */

/* pagination.css */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 8px 16px;
  margin: 0 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-button:hover {
  background-color: #f0f0f0;
}

.pagination-button.active {
  background-color: #000;
  color: #fff;
}

.pagination-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.pagination-button:focus {
  background-color: #000;
  color: #fff;
  /* outline: none; */
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}
